import React, {useEffect, useState} from "react";
import {Box, Button, Dialog, DialogContent, IconButton, Stack, Typography} from "@mui/material";
import questionType from "../assets/data/typeMap";
import {QuestionSwitch} from "./Questions/QuestionSwitch";
import {firestore} from "../firebase/clientApp";
import {useParams} from "react-router-dom";
import {Close} from "@mui/icons-material";
import pleshDark from "../theme/pleshDark";


export const PinnedQuestions = ({questions}) => {
    const [selected, setSelected] = useState('')
    const {id} = useParams()

    const [elementPlaying, setElementPlaying] = useState({})

    useEffect(() => {
        selected !== '' ? getElementPlaying() : setElementPlaying({})
    }, [selected]);

    function getElementPlaying() {
        firestore.collection('users/plesh/events/'+id+'/queue').doc(selected).onSnapshot(s => {
            setElementPlaying({...s.data(), id: s.id})
            return s.data()
        })
    }

    return (
        <Box mt={'35vh'}>
            {selected !== '' && <Dialog open={selected !== ''} onClose={() => setSelected('')} maxWidth={'md'}
                                        fullWidth={true}
                                        fullScreen={elementPlaying.tipo === 'color'}
            >
                <DialogContent sx={{padding:'12px'}} id={'a'+selected} /*id={'pinnedQuestionDialogBg'}*/>
                    <Box sx={{textAlign:'left'}}>
                        <IconButton onClick={() => setSelected('')}
                                    sx={{
                                        color: elementPlaying.tipo === 'color' ? pleshDark.palette.getContrastText(elementPlaying.color) : '#000'
                                    }}>
                            <Close sx={{ color: elementPlaying.tipo === 'color' ? pleshDark.palette.getContrastText(elementPlaying.color) : '#000' }} />
                        </IconButton>
                    </Box>
                    {elementPlaying.id === '7isDEyLsUMHpzpSnO9KP' &&
                        <Typography textAlign={'left'} variant={'body'}>
                            25 anni fa nasceva Fineco, un anno dopo il motore di ricerca più usato al mondo Google. L’emoji più ricercata di sempre è ❤️.
                        </Typography>
                    }
                    <Typography textAlign={'left'} variant={'h5'} mt={'1vh'}
                                sx={{color: elementPlaying.tipo === 'color' ? pleshDark.palette.getContrastText(elementPlaying.color) : 'inherit'}}
                    >
                        {elementPlaying.domanda}
                    </Typography>
                    <QuestionSwitch elementPlaying={elementPlaying}/>
                </DialogContent>
            </Dialog>}
            <Stack spacing={4} justifyContent={'center'} alignItems={'center'}>
                {questions.docs.length === 0 &&
                    <Typography variant={'h5'} maxWidth={'380px'} textAlign={'center'}>
                        Rimani connesso per la prossima interazione!
                    </Typography>}
                {
                    questions.docs.sort((a, b) => a.data().position - b.data().position).map((q, i) => {
                            return <Button key={i} variant={'home-question'} startIcon={questionType[q.data().tipo].icon}
                                           sx={{
                                               background: q.data().tipo === 'color' ? q.data().color : '',
                                               color: q.data().tipo === 'color' ? pleshDark.palette.getContrastText(q.data().color) : ''
                                            }}
                                    onClick={() => setSelected(q.id)}>
                                {q.data().domanda}
                            </Button>
                        }
                    )
                }
            </Stack>
        </Box>
    )
}