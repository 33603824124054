export const API_URL = "https://api.secondstage.app"
//export const API_URL = "http://localhost:8080"
//export const TOOLPRESS_API = "https://api-tool-press-yn26wrnl4q-ey.a.run.app"
export const TOOLPRESS_API = "https://toolpress-api.2ndstage.app"

export const THEME_CONFIG = {
    mode:"dark",
    gradients: {
        default: ['#EA4D95', '#E76E51'],
        home_question: ['#fff', '#fff'],
        applausometro: ['#E5634A', '#E5448A'],
        siNo:['#3a4de4', '#00d8a1'],
        sentiment: ['#3a4de4', '#00d8a1'],
        wordcloud: ['#00539f', '#00539f'],
        qna: ['#EA4D95', '#E76E51'],
    },
    primary: '#f1c443',
    secondary: '#263238',
    accent: '#f1c443',
    text: '#fff',
    //background:"linear-gradient(0deg, rgba(8,25,61,1) 0%, rgba(8,25,61,1) 68%, rgba(9,56,138,1) 82%, rgba(9,56,138,1) 100%)",
    background:"#041a3c",
    poll:{
        default:"#01549f",
        answered:"#f1c443",
        correct: '#3A722D',
        wrong: '#DB0A16'
    }
}